export default {
  lao: "Lao",
  english: "English",
  chinese: "Chinese",
  hal_express: "HAL Express",
  hal_limited: "Houng Ah Loun Logistics co.,ltd",
  quality_award: "Excellence Award",
  news_title: "News",
  about_us_title: "About us",
  contact_us_title: "Contact us",
  service_point_title: "Service point",
  tacking_input: "Tracking parcel ID",
  button_on_follow: "Find",
  service: "Services",
  nav_list_1: "Create order",
  nav_list_2: "Call delivery",
  nav_list_3: "Calculate shipping costs",
  nav_list_4: "Service point",
  nav_list_5: "Cash on delivery",
  nav_list_6: "HAL POINT",
  nav_list_7: "HAL SPEEDY",
  nav_list_8: "HAL VIP",
  nav_list_9: "Terms and conditions",
  nav_list_10: "Contact us",
  phone: "Tel",
  email: "Email",
  name: "Name",
  award_1: "Award",
  title_1: "Top LNCCI Business",
  content_1: "Top LNCCI Business Leader for Year 2021",
  award_2: "Award",
  title_2: "ASEAN BUSINESS AWARDS",
  content_2:
    "Type Parish Awards <br> Conversation - Delivery of Annual Commodity 2021",
  award_3: "Standard",
  title_3: "AN ISO 9001:2015",
  content_3: "International shipping standards",
  award_4: "Award",
  title_4: "Outstanding leading businessmen",
  content_4: "Landlever Business Delivery Rewards Early 2022",
  award_5: "Award",
  title_5: "ASEAN Business Awards",
  content_5:
    "Award for excellence in the field of transportation - delivery of goods for the year 2022",
  contact_us: "Contact us",
  news: "News",
  type_news: "Type of information",
  promotions: "Promotion",
  hal_for_society: "HAL to Society",
  download__app: "Download",
  our_service: "Our service",
  our_service_title_1: "Create order",
  our_service_title_2: "COD",
  our_service_title_3: "Call delivery",
  our_service_title_4: "Calculate shipping costs",
  our_service_title_5: "Service point",
  our_service_title_6: "HAL POINT",
  our_service_title_7: "HAL SPEEDY",
  our_service_title_8: "HAL VIP",
  our_service_content_1:
    "you can make the bill by yourself, without having to wait",
  our_service_content_2:
    "We offer payment destination services to facilitate your sales channels",
  our_service_content_3:
    "For heavy or oversized parcels, you can not take it to deposit by yourself. Please contact us for further assistance",
  our_service_content_4:
    "You can calculate the shipping cost by yourself. This allows you to confirm the shipping fee before making the payment",
  our_service_content_5:
    "Houng Ah Loun has branches throughout the country. You can choose to use the branch near you",
  our_service_content_6:
    "You have point and you can use your points to pay your shipping cost or exchange the products in Houng Ah Loun",
  our_service_content_7:
    "You can upload your parcel lists to an Excel file, for making you for convenience of administration",
  our_service_content_8:
    "The welfare you'll receive as a VIP customer from Houng Ah Loun",
  popular_questions: "Popular questions",
  popular_questions_title_1: "1) Need to apply COD?",
  popular_questions_title_2: "2) Interest in opening a fan shine branch?",
  popular_questions_title_3:
    "3) Please check the bill for me. The parcel is not meant to be received at the branch",
  popular_questions_title_4:
    "4) Please verify the bill for me. I have received the parcel, but I didn't mark it as 'complete'",
  popular_questions_title_5:
    "5) Hal POINT can be used to purchase items? transfer to others? and can also be topped up?",
  popular_questions_title_6:
    "6) Recommended to download the app, why can't I earn points?",
  popular_questions_title_7:
    "7) Check for any notification indicating receipt of cash on delivery (COD) payment, as I haven't yet received the money",
  popular_questions_content_1:
    "May I ask if you have the Houng Ah Loun app? If you don't have it, please download it as you can apply it",
  popular_questions_content_2:
    "Hello, we are Houng Ah Loun Company advise customers to ask for more information ...",
  popular_questions_content_3:
    "Please provide me with information about your parcel's bill. I'll check the status and hand over your parcel",
  popular_questions_content_4:
    "Please provide me with information about your parcel's bill. I'll inform the branch",
  popular_questions_content_5: "Hello, Here is Houng Ah Loun Compay",
  popular_questions_content_5_list_1:
    "Points can be exchanged for products listed in the app",
  popular_questions_content_5_list_2:
    "The points have not been transferred yet. You can only exchange products and top up call value. You can fill the car with oil at the Plus station through a joint program and pay at J-Mart",
  popular_questions_content_6:
    "Hello, Houng Ah Loun is very sorry to inform you that the promotion you recommended your friends to download has expired",
  popular_questions_content_7:
    "Hello, we apologize to our customers. Our system has detected the issue, and we'll update you shortly. Please wait and contact the COD team for further assistance",
  hal_limit: "Houng Ah Loun Logistics co.,ltd",
  hal_limit_content:
    "Established in 2018 by 2 companies: Houng Ah Loun Shipping who has been doing business on the import-export of goods through Denmark and Houng Ah Loun Logistics which engages in business related to the transportation and delivery of parcels throughout the country. Houng Ah Loun Logistics Co.,ltd was officially established on January 29, 2019 Currently, Hung Arun Logistics Limited has a total of 1,121 employees. Headquartered in Vientiane has a total of 507 branches, including 169 capital branches, 93 main branches, and 397 representative branches",
  vision: "Vision",
  vision_content_1:
    "Be a leader in innovative logistics to meet and support the growing e-commerce (E-Commerce) in a comprehensive way",
  vision_content_2: "Utilize modern technology in operational practices",
  vision_content_3: "Expanding business into ASEAN regional countries",
  vision_content_4: "Apply and adapt to the railway",
  vision_content_5: "Promote domestic exports",
  mission: "Mission",
  mission_content_1:
    "Make the deposit parcel or trade of people of all professions convenient",
  mission_content_2:
    "Assist in the wider market dissemination of traditional products (One District One Product - ODOP), expand exportation, and enhance fund circulation efficiency",
  mission_content_3:
    "t's a small part in stimulating the domestic economy for gradual improvement",
  purpose: "Purpose",
  purpose_content_1:
    "Hal Express establish branches in every province across the country",
  purpose_content_2: "Plan to expand the service point to the distric",
  purpose_content_3:
    "Planning to expand business to international services, beginning with China, Thailand, and Vietnam",
  purpose_content_4: "A large-scale warehouse system to serve customers",
  contact_hotline: "Call urgently",
  contact_location_title: "Location",
  contact_location_content:
    "Jommany Village, Xaysetha District, Vientiane Capital",
  contact_send_us_message: "Send us a message",
  contact_send_us_message_detail: "Contact us when you need more information",
  contact_input_topic: "Title",
  contact_input_message: "Message",
  contact_button_send_message: "Send Message",
  contact_follow_at: "Follow us at",
  news_type: "Type of news",
  news_type_title_1: "News",
  news_type_title_2: "Promotion",
  news_type_title_3: "HAL to Society",
  nearest_branches: "Nearby branches",
  search_branches: "Search branch",
  search_all_branches: "Search all branch",
  chose_province: "Select province",
  chose_district: "Select district",
  footer_our_service_1: "Create order",
  footer_our_service_2: "Cash on delivery",
  footer_our_service_3: "Call delivery",
  footer_our_service_4: "Service point",
  footer_our_service_5: "Terms and Conditions",
  footer_our_service_6: "HAL POINT",
  footer_our_service_7: "HAL SPEEDY",
  footer_our_service_8: "HAL VIP",
  footer_download_app: "Download the App",
  footer_our_privacy: "Privacy Policy",

  not_found: "No result found",
  // ! dialog
  dialog_hal_exxpress_title: "Call delivery",
  dialog_hal_exxpress_content:
    "Install and register the HAL EXPRESS App for use Call delivery service",
  dialog_hal_vip_title: " HAL VIP",
  dialog_hal_vip_content: "HAL VIP service will be available soon!",
  dialog_hal_speedy_title: "HAL Speedy",
  dialog_hal_speedy_content: "HAL Speedy will be available soon!",
  dialog_hal_point_title: "HAL Point",
  dialog_hal_point_content:
    "When you receive points from HAL, you can use them to exchange product or service charges of Houng Ah Loun",
  //! calc
  calc_title: "Calculate the service fee",
  calc_start_branch: "Origin Branch",
  calc_end_branch: "Destination Branch",
  calc_start_branch_choose: "Choose Origin Branch",
  calc_end_branch_chose: "Choose Destination Branch",
  calc_width: "Width",
  calc_height: "Height",
  calc_length: "Lenght",
  calc_weight: "Weight",
  calc_input_width: "Input Width",
  calc_input_height: "Input Height",
  calc_input_length: "Input Lenght",
  calc_input_weight: "Parcel Weight",
  calc_kip: "Kip",
  calc_price: "Product Price",
  //  ! benfit
  // Benefits of membership 会员权益
  benfit_head: "membership",
  // ລະບົບຈັດການຂົນສົ່ງ Shipment management 装运管理
  benfit_title_1: "Shipment management",
  //ສະສົມຄະແນນ Collect point 积分收集点
  benfit_title_2: "Collect point",
  // ທ່ານສາມາດໃຊ້ບໍລິການຕ່າງໆບໍວ່າຈະເປັນຝາກເຄື່ອງເອງ You can create order, use COD service and request pickup 您可以创建订单，使用代货收款服务和请求提货
  benfit_content_1: "You can create order, use COD service and request pickup",
  // ເມື່ອທ່ານໃຊ້ບໍລິການຝາກເຄື່ອງ,ຮັບເຄື່ອງ ທ່ານຈະໄດ້ຮັບຄະແນນສະສົມເພື່ອແລກສິດ ພິເສດ Once you use our service, you will receive our points 一旦您使用我们的服务，您将获得我们的积分
  benfit_content_2: "Once you use our service, you will receive our points",

  // ລົງທະບຽນ Register 注册
  header: "Register",
  login: "Login",
  register: "Register",
  // ເພື່ອໃຊ້ບໍລິການຂອງ Hal Express To use HAL Express service 使用红阿伦快递公司服务
  header__Content: "To use HAL Express service",
  // ຍິນດີຕ້ອນຮັບ Welcome 欢迎光临
  title: "Welcome",
  // ເຂົ້າສູ່ລະບົບ ເພື່ອຮັບສິດໃນການໃຊ້ ບໍລິການຕ່າງໆ ຂອງ HAL Expressແລະ ສິດທິໃນການສະສົມຄະແນນເພື່ອຮັບຂອງລາງວັນຕ່າງໆ Login to use our services and claim a lot of prizes 进入红阿伦快递软件已使用 您就可以获得积分，更多的礼物
  title__content: "Login to use our services and claim a lot of prizes",
  // --------card------

  place__name: "Name",

  // ນາມສະກຸນ Surname 姓
  last: "Surname",
  place__last: "Surname",
  // ວັນເດືອນປີເກີດ Date of birth 出生日期
  dob: "Date of birth",
  // Mobile number 电话号码
  phone__number: "Mobile number",
  place__number: "01234567",
  // Password 验证密码
  pass: "Password",
  place__pass: "Password",
  // Gender 性别
  gender: "Gender",
  // Male 男
  male: "Male",
  // Female 女
  female: "Female",
  // successful 成功的
  btn: "Successful",
  // ຍອມຮັບເງື່ອນໄຂ ແລະ ຂໍ້ກຳນົດ  Accept conditions and policies 接受各个条件和禁止
  remark: "Accept conditions and policies ",
  // ອ່ານເພີ່ມເຕີມ Read more 阅读更多
  readMore: "Read more",
  // OTP box confirm 确认
  otp__title: "confirm",
  otp__head: "",
  otp__token_end: "",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  otp__callagain: "Please try again",
  // ກະລຸນາໃສ່ລະຫັດ ທີ່ທ່ານໄດ້ຮັບທາງຂໍ້ຄວາມ Please enter the Password sent to your SMS 请输入您手机收到的短信验证码
  otp__getInbox: "Please enter the Password sent to your SMS",
  // ຫາກທ່ານຍັງບໍ່ໄດ້ຮັບຂໍ້ຄວາມ If you do not receive message 如果您没有收到信息
  otp__Ifnotget: "If you do not receive message",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  click_tosend: "Please try again",
  // OPT ERR
  // ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ Confirmed Password not correct 验证码错误
  otp_err: "Confirmed Password not correct",
  //
  // validation
  // ຕ້ອງລະບຸຊື່ Please input your name 请填写名
  validate_name: "Please input your name",
  // ຕ້ອງລະບຸນາມສະກຸນ Please input your surname 请填写姓
  validate_lastname: "Please input your surname",
  // ຕ້ອງລະບຸເບີໂທ Phone can't be empty 请填写电话号码
  validate_phone: `Phone can't be empty`,
  // Phone must be 8 digits 电话号码必须是8位数字
  validate_phoneLength: "Phone must be 8 digits",
  // ຕ້ອງລະບຸລະຫັດຜ່ານ  Please input Password 请输入密码
  validate_pass: "Please input Password",
  // gender ---Please pick gender 请选择性别
  validate_gender: "Please pick gender",
  // ລະຫັດຜ່ານຢ່າງນ້ອຍ 6 ຫຼັກ Password must be more than 6 digits 密码不可少于6位数
  validate_passLength: "Password must be more than 6 digits",
  // ຕ້ອງຍອມຮັບເງື່ອນໄຂກ່ອນ Please accept the conditions 只有接受条件才能继续
  validate_accept: "Please accept the conditions",
  // ເບີນີ້ມີຢູ່ໃນລະບົບແລ້ວ ກະລຸນາເຂົ້າສູ່ລະບົບ This number has been already registered, please log in 该电话号码已经注册过，请直接登录
  haveAnumber: "This number has been already registered, please log in",
  // ເບີນີ້ຂໍເກີນຈໍານວນຄັ້ງ ກະລຸນາລອງໃຫມ່ພາຍຫລັງ 4 ຊົ່ວໂມງ Too many requests, please try again in 4 hours 登录次数过多，请等4个小时后再操作
  limit: "Too many requests, please try again in 4 hours",
};
