export default {
  // !core and navigation
  // ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ
  lao: "老挝",
  english: "英语",
  chinese: "中国人",
  hal_express: "HAL Express",
  hal_limited: "Houng Ah Loun Logistics co.,ltd",
  quality_award: "卓越奖",
  news_title: "消息",
  about_us_title: "关于我们",
  contact_us_title: "联系我们",
  service_point_title: "服务点",
  tacking_input: "Tracking parcel ID",
  button_on_follow: "寻找",
  service: "服务",
  nav_list_1: "创建订单",
  nav_list_2: "致电交付",
  nav_list_3: "计算运输成本",
  nav_list_4: "服务点",
  nav_list_5: "货到付款",
  nav_list_6: "HAL POINT",
  nav_list_7: "HAL SPEEDY",
  nav_list_8: "HAL VIP",
  nav_list_9: "条款和条件",
  nav_list_10: "联系我们",
  phone: "电话",
  email: "电子邮件",
  name: "姓名",
  award_1: "奖",
  title_1: "顶级LNCCI业务",
  content_1: "2021年的最高LNCCI业务负责人",
  award_2: "奖",
  title_2: "东盟商业奖",
  content_2: "类型教区奖<br>对话 - 年度商品的交付2021",
  award_3: "标准",
  title_3: "ISO 9001：2015",
  content_3: "国际运输标准",
  award_4: "奖",
  title_4: "杰出的领先商人",
  content_4: "Landlever业务交付奖励2022年初",
  award_5: "奖",
  title_5: "东盟商业奖",
  content_5: "运输领域卓越奖 -  2022年的商品交付",
  contact_us: "联系我们",
  news: "消息",
  type_news: "信息类型",
  promotions: "晋升",
  hal_for_society: "HAL to Society",
  download__app: "下载",
  our_service: "我们的服务",
  our_service_title_1: "创建订单",
  our_service_title_2: "COD",
  our_service_title_3: "致电交付",
  our_service_title_4: "计算运输成本",
  our_service_title_5: "服务点",
  our_service_title_6: "HAL POINT",
  our_service_title_7: "HAL SPEEDY",
  our_service_title_8: "HAL VIP",
  our_service_content_1: "您可以自己制作账单，而不必等待",
  our_service_content_2: "我们提供付款目的地服务以促进您的销售渠道",
  our_service_content_3:
    "对于重型或超大包裹，您无法独自存入它。请与我们联系以获取进一步的帮助",
  our_service_content_4: "您可以自己计算运输成本。这使您可以在付款之前确认运费",
  our_service_content_5:
    "Houng Ah Loun在全国各地设有分支机构。您可以选择使用附近的分支",
  our_service_content_6:
    "您有要点，您可以使用积分来支付运输费用或在Houng Ah Loun中交换产品",
  our_service_content_7:
    "您可以将包裹列表上传到Excel文件，以便您方便地进行管理",
  our_service_content_8: "您将从Houng Ah Loun获得VIP客户的福利",
  popular_questions: "流行问题",
  popular_questions_title_1: "1）需要应用鳕鱼吗？",
  popular_questions_title_2: "2）有兴趣打开风扇闪耀分支吗？",
  popular_questions_title_3: "3）请为我检查帐单。包裹不打算在分支机构接收",
  popular_questions_title_4:
    "4）请为我验证账单。我收到了包裹，但我没有将其标记为“完整”",
  popular_questions_title_5:
    "5）HAL点可用于购买商品？转移到他人？而且也可以被上满吗？",
  popular_questions_title_6: "6）建议下载该应用程序，为什么我不能赚取积分？",
  popular_questions_title_7:
    "7）检查任何指示收到货币现金（COD）付款的通知，因为我尚未收到钱",
  popular_questions_content_1:
    "请问您是否有Hung Ah Loun应用程序？如果您没有，请下载它，因为您可以应用它",
  popular_questions_content_2:
    "您好，我们是Houng Ah Loun Company建议客户要求提供更多信息...",
  popular_questions_content_3:
    "请向我提供有关您包裹账单的信息。我会检查状态并交出您的包裹",
  popular_questions_content_4:
    "请向我提供有关您包裹账单的信息。我会通知分支机构",
  popular_questions_content_5: "您好，这是Houng Ah Loun Compay",
  popular_questions_content_5_list_1: "可以将积分交换为应用程序中列出的产品",
  popular_questions_content_5_list_2:
    "这些要点尚未转移。您只能交换产品并充值呼叫值。您可以通过联合计划在Plus站填充汽车，并在J-Mart付款",
  popular_questions_content_6:
    "您好，Houng Ah Loun非常遗憾地通知您，您建议您的朋友下载的促销活动已过期",
  popular_questions_content_7:
    "您好，我们向客户道歉。我们的系统已经检测到了问题，我们很快会更新您。请等待并联系COD团队以获取进一步的帮助",
  hal_limit: "Houng AH Loun Logistics Co。，Ltd",
  hal_limit_content:
    "由2家公司成立于2018年：Houng Ah Loun Shipping在通过丹麦和Houng Ah Loun Logistics在商品进出口进行业务开展业务，这些物流从事与全国各地包裹的运输和交付有关的业务。 Houng AH Loun Logistics Co.，Ltd于2019年1月29日正式成立，Hung Arun Logistics Limited共有1,121名员工。 Vientiane的总部共有507个分支机构，包括169个资本分支，93个主要分支机构和397个代表分支机构",
  vision: "想象",
  vision_content_1:
    "成为创新物流领域的领导者，以全面的方式见到和支持不断增长的电子商务（电子商务）",
  vision_content_2: "利用现代技术在运营实践中",
  vision_content_3: "将业务扩展到东盟地区国家",
  vision_content_4: "申请并适应铁路",
  vision_content_5: "促进国内出口",
  mission: "纽带",
  mission_content_1: "使所有专业的人的存款包裹或交易方便",
  mission_content_2:
    "协助更广泛的市场传播传统产品（一区产品-ODOP），扩大出口并增强基金发行效率",
  mission_content_3: "T在刺激国内经济以逐步改善方面是一小部分",
  purpose: "目的",
  purpose_content_1: "HAL Express在全国每个省建立分支机构",
  purpose_content_2: "计划将服务点扩展到干扰",
  purpose_content_3: "计划从中国，泰国和越南开始将业务扩展到国际服务",
  purpose_content_4: "一个为客户服务的大规模仓库系统",
  contact_hotline: "紧急打电话",
  contact_location_title: "地点",
  contact_location_content:
    "Vientiane Capital Xaysetha District的Jommany Village",
  contact_send_us_message: "给我们发信息",
  contact_send_us_message_detail: "当您需要更多信息时与我们联系",
  contact_input_topic: "标题",
  contact_input_message: "信息",
  contact_button_send_message: "发信息",
  contact_follow_at: "跟随我们",
  news_type: "新闻类型",
  news_type_title_1: "消息",
  news_type_title_2: "晋升",
  news_type_title_3: "HAL to Society",
  nearest_branches: "附近的分支机构",
  search_branches: "搜索分支",
  search_all_branches: "搜索所有分支",
  chose_province: "选择省",
  chose_district: "选择区",
  footer_our_service_1: "创建订单",
  footer_our_service_2: "货到付款",
  footer_our_service_3: "致电交付",
  footer_our_service_4: "服务点",
  footer_our_service_5: "条款和条件",
  footer_our_service_6: "HAL POINT",
  footer_our_service_7: "HAL SPEEDY",
  footer_our_service_8: "HAL VIP",
  footer_download_app: "下载应用程序",
  footer_our_privacy: "Privacy Policy",
  not_found: "No result found",

  // ! dialog
  dialog_hal_exxpress_title: "Call delivery",
  dialog_hal_exxpress_content:
    "安装并注册HAL Express应用程序以供使用呼叫传送服务",
  dialog_hal_vip_title: "HAL VIP",
  dialog_hal_vip_content: "HAL VIP服务即将提供!",
  dialog_hal_speedy_title: "HAL Speedy",
  dialog_hal_speedy_content: "Hal Speedy即将提供!",
  dialog_hal_point_title: "HAL Point",
  dialog_hal_point_content:
    "当您收到HAL的积分时, 您可以使用它们来交换Houng Ah Loun的产品或服务费用",

  //  ! calc

  calc_title: "计算服务费",
  calc_start_branch: "ສາຂາຕົ້ນທາງ",
  calc_end_branch: "ສາຂາປາຍທາງ",
  calc_start_branch_choose: "选择始发站点",
  calc_end_branch_chose: "选择目的站点",
  calc_width: "宽度",
  calc_height: "高度",
  calc_length: "长度",
  calc_weight: "重量",
  calc_input_width: "输入宽度",
  calc_input_height: "输入高度",
  calc_input_length: "输入长度",
  calc_input_weight: "包裹重量",
  calc_kip: "Kip",
  calc_price: "商品价格",
  // !  benfit
  // Benefits of membership 会员权益
  benfit_head: "会员权益",
  // ລະບົບຈັດການຂົນສົ່ງ Shipment management 装运管理
  benfit_title_1: "装运管理",
  //ສະສົມຄະແນນ Collect point 积分收集点
  benfit_title_2: "积分收集点",
  // ທ່ານສາມາດໃຊ້ບໍລິການຕ່າງໆບໍວ່າຈະເປັນຝາກເຄື່ອງເອງ You can create order, use COD service and request pickup 您可以创建订单，使用代货收款服务和请求提货
  benfit_content_1: "您可以创建订单，使用代货收款服务和请求提货",
  // ເມື່ອທ່ານໃຊ້ບໍລິການຝາກເຄື່ອງ,ຮັບເຄື່ອງ ທ່ານຈະໄດ້ຮັບຄະແນນສະສົມເພື່ອແລກສິດ ພິເສດ Once you use our service, you will receive our points 一旦您使用我们的服务，您将获得我们的积分
  benfit_content_2: "一旦您使用我们的服务，您将获得我们的积分",
  //!   register

  // ລົງທະບຽນ Register 注册
  header: "注册",
  login: "Login",
  register: "Register",
  // ເພື່ອໃຊ້ບໍລິການຂອງ Hal Express To use HAL Express service 使用红阿伦快递公司服务
  header__Content: "使用红阿伦快递公司服务",
  // ຍິນດີຕ້ອນຮັບ Welcome 欢迎光临
  title: "欢迎光临",
  // ເຂົ້າສູ່ລະບົບ ເພື່ອຮັບສິດໃນການໃຊ້ ບໍລິການຕ່າງໆ ຂອງ HAL Expressແລະ ສິດທິໃນການສະສົມຄະແນນເພື່ອຮັບຂອງລາງວັນຕ່າງໆ Login to use our services and claim a lot of prizes 进入红阿伦快递软件已使用 您就可以获得积分，更多的礼物
  title__content: "进入红阿伦快递软件已使用<br> 您就可以获得积分，更多的礼物",
  // --------card------
  // ຊື່ Name 名

  place__name: "名",

  // ນາມສະກຸນ Surname 姓
  last: "姓",
  place__last: "姓",
  // ວັນເດືອນປີເກີດ Date of birth 出生日期
  dob: "出生日期",
  // Mobile number 电话号码
  phone__number: "电话号码",
  place__number: "01234567",
  // Password 验证密码
  pass: "验证密码",
  place__pass: "验证密码",
  // Gender 性别
  gender: "性别",
  // Male 男
  male: "男",
  // Female 女
  female: "女",
  // successful 成功的
  btn: "成功的",
  // ຍອມຮັບເງື່ອນໄຂ ແລະ ຂໍ້ກຳນົດ  Accept conditions and policies 接受各个条件和禁止
  remark: "接受各个条件和禁止 ",
  // ອ່ານເພີ່ມເຕີມ Read more 阅读更多
  readMore: "阅读更多",
  // OTP box confirm 确认
  otp__title: "确认",
  otp__head: "",
  otp__token_end: "",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  otp__callagain: "再次发送",
  // ກະລຸນາໃສ່ລະຫັດ ທີ່ທ່ານໄດ້ຮັບທາງຂໍ້ຄວາມ Please enter the Password sent to your SMS 请输入您手机收到的短信验证码
  otp__getInbox: "请输入您手机收到的短信验证码",
  // ຫາກທ່ານຍັງບໍ່ໄດ້ຮັບຂໍ້ຄວາມ If you do not receive message 如果您没有收到信息
  otp__Ifnotget: "如果您没有收到信息",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  click_tosend: "再次发送",
  // OPT ERR
  // ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ Confirmed Password not correct 验证码错误
  otp_err: "验证码错误",
  //
  // validation
  // ຕ້ອງລະບຸຊື່ Please input your name 请填写名
  validate_name: "请填写名",
  // ຕ້ອງລະບຸນາມສະກຸນ Please input your surname 请填写姓
  validate_lastname: "请填写姓",
  // ຕ້ອງລະບຸເບີໂທ Phone can't be empty 请填写电话号码
  validate_phone: "请填写电话号码",
  // Phone must be 8 digits 电话号码必须是8位数字
  validate_phoneLength: "电话号码必须是8位数字",
  // ຕ້ອງລະບຸລະຫັດຜ່ານ  Please input Password 请输入密码
  validate_pass: "请输入密码",
  // gender ---Please pick gender 请选择性别
  validate_gender: "请选择性别",
  // ລະຫັດຜ່ານຢ່າງນ້ອຍ 6 ຫຼັກ Password must be more than 6 digits 密码不可少于6位数
  validate_passLength: "密码不可少于6位数",
  // ຕ້ອງຍອມຮັບເງື່ອນໄຂກ່ອນ Please accept the conditions 只有接受条件才能继续
  validate_accept: "只有接受条件才能继续",
  // ເບີນີ້ມີຢູ່ໃນລະບົບແລ້ວ ກະລຸນາເຂົ້າສູ່ລະບົບ This number has been already registered, please log in 该电话号码已经注册过，请直接登录
  haveAnumber: "该电话号码已经注册过，请直接登录",
  // ເບີນີ້ຂໍເກີນຈໍານວນຄັ້ງ ກະລຸນາລອງໃຫມ່ພາຍຫລັງ 4 ຊົ່ວໂມງ Too many requests, please try again in 4 hours 登录次数过多，请等4个小时后再操作
  limit: "登录次数过多, 请等4个小时后再操作",
  // ການລົງທະບຽນລົ້ມເຫຼວ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ
};
